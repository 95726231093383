const Config = {
  checkDomain : () => {
    let windowLoc = window.location.hostname
    
    if(windowLoc != 'admin.sodapos.com'){
      return 'https://api-dev.sodapos.com/u/'
      // return 'http://localhost/soda-api-fork/u/'
    } else {
      return 'https://api.sodapos.com/u/'
    }
  }
}

export default Config